import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`You can find me on other platforms: `}<a parentName="p" {...{
        "href": "https://twitter.com/josuablejeru"
      }}>{`Twitter`}</a>{` & `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/josua-blejeru-a2871a164/"
      }}>{`LinkedIn`}</a></p>
    <p>{`or `}<a parentName="p" {...{
        "href": "https://www.buymeacoffee.com/josuablejeru"
      }}>{`buy me a coffee`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      