import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <p>{`Welcome to my personal website! I am a software engineer with a focus on DevOps, Cloud and Data science.`}</p>
    <p>{`I began my career as a CNC machinist apprentice, which gave me a solid foundation in precision and attention to detail.
That's why I have always been eager to learn new things, and I continue to expand my skillset every day.`}</p>
    <p>{`As a software engineer, I specialize in building and maintaining efficient and scalable systems.
My focus on DevOps allows me to ensure that the systems I build are reliable and easy to manage, particularly in cloud environments.
Additionally I have an interest in Data Science and use this skills to analyze and make data-driven decisions for my projects.`}</p>
    <p>{`Throughout my career, I have gained experience with a variety of tools and technologies, and I am always looking for new challenges.`}</p>
    <p>{`Thank you for visiting my website, and I hope you find the content here interesting and informative.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      